(function($){

    /**
     * initializeBlock
     *
     * Adds custom JavaScript to the block HTML.
     *
     * @date    15/4/19
     * @since   1.0.0
     *
     * @param   object $block The block jQuery element.
     * @param   object attributes The block attributes (only available when editing).
     * @return  void
     */
    var initializeBlock = function( $block ) {
        //$block.find('img').doSomething();
        console.log("BLOCK | Init | ", "Section Image")

	

		// Scroll Magic
		function scrolltime() {
			//DEV MODE
			var DEVMODE = false;
			//Scroll Magic Controller
			var sm_cont = new ScrollMagic.Controller({});

			//universal parallax
			var sm_e_parallax = $("#acf-section-image .hero");
			if (sm_e_parallax.length) {
				sm_e_parallax.each(function() {
					var sm_s_parallax = new ScrollMagic.Scene({
							triggerElement: this,
							triggerHook: "onEnter",
							duration: "200%"
						})
						.setTween(TweenMax.to(sm_e_parallax.children(".bg-wrap").children(".bg"), 1, {
							y: '-25%',
							autoAlpha: 1,
							ease: Power0.easeNone
						}))
						.addTo(sm_cont);
					if (DEVMODE) {
						sm_s_parallax.addIndicators({
							name: "parallax",
							indent: 0,
							colorStart: "#07ff00",
							colorEnd: "#ff0000",
							colorTrigger: "#ff9700"
						})
					};
				});
			}

		};
		scrolltime();

    }

    // Initialize each block on page load (front end).
    $(document).ready(function(){
        $('#acf-section-image').each(function(){
            initializeBlock( $(this) );
        });
    });
    // Initialize dynamic block preview (editor).
    if( window.acf ) {
        //console.log("Window ACF")
        window.acf.addAction( 'render_block_preview', initializeBlock );
    }

})(jQuery);